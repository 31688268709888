import React from "react";
import Lottie from "lottie-react-web";
import domtoimage from "dom-to-image";
import { AudioManager, IMusicURL } from "./utils/AudioManager";
import { animation } from "./data/data";
import { animationBg } from "./data/data-light";
import { animationBgInit } from "./data/data-light-init";
import { animationButtonShare } from "./data/data-button-share";
import { animationMain } from "./data/data-main";
import "./App.css";
import { getOperatingSystem } from "./utils/commonUtils";
import DownloadDialog from "./components/DownloadDialog";
import {
  arrayQuote,
  LINK_DOWNLOAD_ANDROID,
  LINK_DOWNLOAD_IOS,
} from "./utils/constant";
import { animationComplete } from "./data/data-complete";

const rand = (myArray: string[]) => {
  return myArray[(Math.random() * myArray.length) | 0];
};

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animation,
};

const defaultOptionsBg = {
  loop: true,
  autoplay: true,
  animationData: animationBg,
};

const defaultOptionsBgInit = {
  loop: true,
  autoplay: true,
  animationData: animationBgInit,
};

const defaultOptionMain = {
  loop: true,
  autoplay: true,
  animationData: animationMain,
};

const defaultOptionMainComplete = {
  loop: true,
  autoplay: true,
  animationData: animationComplete,
};

const defaultOptionsButtonShare = {
  loop: true,
  autoplay: true,
  animationData: animationButtonShare,
};

const data: Array<IMusicURL> = [
  {
    url: "/assets/BackgroundSound.mp3",
    name: "background",
  },
  {
    url: "/assets/ClickSound.mp3",
    name: "click",
  },
  {
    url: "https://assets-cdn.kahoot.it/player/v2/assets/points.36015560.mp3",
    name: "open",
  },
  {
    url: "https://assets-cdn.kahoot.it/player/v2/assets/TheEnd.3c6ff735.mp3",
    name: "success",
  },
];

function App() {
  const audioRef = React.useRef<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [started, setStarted] = React.useState<boolean>(false);
  const [isStopped, setIsStoped] = React.useState<boolean>(false);
  const [clicked, setClicked] = React.useState<boolean>(false);
  const [openDownload, setOpenDownload] = React.useState<boolean>(false);
  const [openQR, setOpenQR] = React.useState<boolean>(false);
  const [isComplete, setIscomplete] = React.useState<boolean>(false);
  const [base64, setBase64] = React.useState<string>("");
  const system = getOperatingSystem();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setIscomplete(params.get("is_expired") === "true" ? true : false);
  }, []);

  React.useEffect(() => {
    if (!loading) {
      setIsStoped(true);
    }
  }, [loading]);

  React.useEffect(() => {
    const windowHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--window-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", windowHeight);
    windowHeight();
  }, []);

  React.useEffect(() => {
    audioRef.current = new AudioManager();
    audioRef.current.initAudio(data, () => {
      setLoading(false);
      audioRef.current.start("background", true, 0.3);
    });
  }, []);

  React.useEffect(() => {
    if (system === "Android") {
      document.addEventListener("message", function (event) {
        const response = JSON.parse((event as any).data);
        setIscomplete(
          response.status.toLowerCase() === "success" ? true : false
        );
      });
    }
    if (system === "iOS") {
      window.addEventListener("message", function (event) {
        const response = JSON.parse((event as any).data);
        setIscomplete(
          response.status.toLowerCase() === "success" ? true : false
        );
      });
    }
  }, [system]);

  const randomText = rand(arrayQuote) || arrayQuote[0];
  const randomTextArray = randomText.split(",");

  return (
    <div
      onClick={() => {
        if (!started) {
          audioRef.current.start("background", true, 1);
          setStarted(true);
        }
      }}
      id="content-image"
    >
      <div className="app-body">
        <img
          width="100%"
          className="background"
          src={
            system === "iOS" ? "/assets/BG50px.svg" : "/assets/background.png"
          }
          alt=""
        />
        <img
          style={{
            display: clicked ? "" : "none",
          }}
          width="100%"
          className="light-bg"
          src={system === "iOS" ? "/assets/Light.svg" : "/assets/Light.png"}
          alt=""
        />
        {loading ? (
          <div></div>
        ) : (
          <>
            <div
              style={{
                display: clicked ? "" : "none",
                bottom: clicked ? "40px" : "0px",
              }}
              className="main-animation"
            >
              <Lottie
                ariaLabel="311313"
                options={defaultOptions}
                isStopped={isStopped}
              />
            </div>
            <div
              style={{ display: clicked ? "none" : "" }}
              className="bg-animation"
            >
              <Lottie options={defaultOptionsBgInit} />
            </div>
            <div
              style={{ display: clicked ? "" : "none" }}
              className="bg-animation"
            >
              <Lottie options={defaultOptionsBg} isStopped={isStopped} />
            </div>
            <div
              style={{ display: clicked ? "none" : "" }}
              className="init-animation"
            >
              {isComplete ? (
                <Lottie options={defaultOptionMainComplete} />
              ) : (
                <Lottie options={defaultOptionMain} />
              )}
              {!clicked && !isComplete && (
                <button
                  className="button-action"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsStoped(false);
                    setClicked(true);
                    audioRef.current.start("click", false, 1);
                    audioRef.current.start("open", false, 1);
                    setTimeout(() => {
                      audioRef.current.stop("background");
                      audioRef.current.start("success", false, 1);
                    }, 1500);
                    const el1 = document.querySelector(
                      '[aria-label="Hôm nay bán ra trăm đơn luôn nha"]'
                    );

                    if (el1) {
                      el1.innerHTML = `<g class='point' stroke-linecap='butt' transform='matrix(1,0,0,1,0,0)'>
                          <g font-family='KondeRegular' font-size=${
                            randomTextArray.length === 1 ? "2.2rem" : "2.4rem"
                          } x='0' y='0'>
                            ${randomTextArray.map(
                              (text: any, index: number) => {
                                return `<text x='0' width='100' dy=${
                                  index === 0 ? "0em" : "1.5em"
                                }>
                                  ${text}
                                </text>`;
                              }
                            )}
                          </g>
                        </g>`;
                    }
                    (window as any).ReactNativeWebView?.postMessage(
                      "pray_success"
                    );
                  }}
                />
              )}
            </div>
            {(clicked || isComplete) && (
              <>
                <img
                  className="img-qrcode"
                  src="/assets/QRFooter.svg"
                  style={{ display: openQR ? "" : "none" }}
                  alt="QR code"
                />
                <button
                  className="button-share"
                  style={{
                    display: openQR ? "none" : "",
                    height: isComplete ? "130px" : "auto",
                    bottom: isComplete ? "30px" : "0px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    !isComplete && setOpenQR(true);
                    if (system !== "Android" && system !== "iOS")
                      return setOpenDownload(true);
                    if (base64) {
                      return (window as any).ReactNativeWebView?.postMessage(
                        base64
                      );
                    }
                    if (isComplete) {
                      return (window as any).ReactNativeWebView?.postMessage(
                        system === "Android"
                          ? LINK_DOWNLOAD_ANDROID
                          : LINK_DOWNLOAD_IOS
                      );
                    }
                    setTimeout(() => {
                      var node = document.getElementById("content-image");
                      if (node) {
                        domtoimage
                          .toJpeg(node)
                          .then(function (dataUrl) {
                            setBase64(dataUrl);
                            (window as any).ReactNativeWebView?.postMessage(
                              dataUrl
                            );
                          })
                          .catch(function (error) {
                            console.error("oops, something went wrong!", error);
                          });
                      }
                    }, 0);
                  }}
                >
                  {!isComplete && (
                    <Lottie options={defaultOptionsButtonShare} />
                  )}
                </button>
              </>
            )}
          </>
        )}
      </div>
      {openDownload && (
        <DownloadDialog onClose={() => setOpenDownload(false)} />
      )}
    </div>
  );
}

export default App;
