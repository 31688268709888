import { useEffect } from "react";
import { getOperatingSystem } from "../../utils/commonUtils";
import {
  DEEP_LINK_APP,
  LINK_DOWNLOAD_ANDROID,
  LINK_DOWNLOAD_IOS,
} from "../../utils/constant";

type DownloadDialogProps = {
  onClose: () => void;
};

function DownloadDialog({ onClose }: DownloadDialogProps) {
  function clickOutside(event: any) {
    const modal = document.getElementById("myModal");
    if (modal && event.target == modal) {
      modal.style.display = "none";
      onClose();
    }
  }

  useEffect(() => {
    const modal = document.getElementById("myModal");
    if (modal) {
      modal.style.display = "flex";
      modal.style.alignItems = "center";
      modal.style.justifyContent = "center";
    }
    window.addEventListener("click", clickOutside, false);
    return () => {
      window.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const generateImageButton = () => {
    const os = getOperatingSystem();
    if (os === "MacOS") {
      return "/assets/AppStore.png";
    }
    return "/assets/GooglePlay.png";
  };

  const handleClickDownload = () => {
    const win: Window = window;
    win.location = DEEP_LINK_APP;
    setTimeout(() => {
      if (getOperatingSystem() === "iOS") {
        window.location.replace(LINK_DOWNLOAD_IOS);
      } else {
        window.location.replace(LINK_DOWNLOAD_ANDROID);
      }
    }, 10000);
  };

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <p>Mời bạn download</p>
        <div className="action-content">
          <img src="/assets/logo.svg" alt="logo" />
          <img
            src={generateImageButton()}
            onClick={handleClickDownload}
            alt="google play"
            className="download-button"
          />
        </div>
      </div>
    </div>
  );
}

export default DownloadDialog;
