export const DEEP_LINK_APP = "me.finan.app://";
export const LINK_DOWNLOAD_ANDROID =
  "https://play.google.com/store/apps/details?id=me.finan.app";
export const LINK_DOWNLOAD_IOS =
  "https://apps.apple.com/vn/app/s%E1%BB%95-b%C3%A1n-h%C3%A0ng-qu%E1%BA%A3n-l%C3%BD-b%C3%A1n-h%C3%A0ng/id1560099589";

export const arrayQuote = [
  "Tiền vào như nước, Tiền ra nhỏ giọt",
  "Năm mới an khang, Vạn điều may mắn",
  "Buôn may bán đắt, Tiền đầy két sắt",
  "Tiền vàng đầy hũ, Hạnh phúc bao la",
  "Quanh năm đắt khách, Tài lộc đầy ngóc ngách",
  "Vàng đeo đầy tay, mua may bán đắt",
  "Buôn bán bội thu, Đếm tiền không xuể",
  "Thần tài đến, Hạnh phúc vô bờ bến",
  "Khai xuân đón lộc, Rước vía Thần Tài",
  "Nhà nhà ấm no, Tấn tài tấn lộc",
  "Chốt đơn liền tay, Tiền bạc hăng say",
  "An khang thịnh vượng, Vạn sự như ý ",
  "Công việc thuận lợi, Niềm vui phơi phới",
  "Quán đông tấp nập, Tiền vào tới tấp",
  "Phúc lộc quanh năm, Mua may bán đắt",
  "Thần Tài gõ cửa, Làm ăn phát lộc",
  "THẦN TÀI đến, TÚI VÀNG nè!",
  "Khách tới ù ù, Vàng đầy ú ụ",
  "Quanh năm đắt khách, Tài lộc sinh sôi",
  "Năm Mão bão đơn, Cả năm sung túc ",
  "Quý Mão đang sang, Ngập ngàn may mắn",
  "Tiền tài dư dả, Sung túc cả năm",
  "Ăn nên làm ra, Cả nhà cười HAHA",
  "Lộc đến ê hề, Hạnh phúc tràn trề ",
  "Tiền lộc đầy ắp, Xuân tràn yêu thương",
  "Vàng bạc đầy túi, Phúc lộc như núi",
  "Xuân 2023 đã tới, Tài lộc phơi phới",
  "Tân niên may mắn, Nhận vạn lộc may",
  "Năm mới triệu đơn, Công thành danh toại",
  "Năm mới hoan hỉ, Kinh doanh phát đạt",
  "Quý Mão sang trang, Phú quý ngập tràn",
  "Khách đến tấp nập, Tràn ngập đơn hàng",
  "Sự nghiệp lên hương, Sức khỏe dồi dào",
  "Tiền nhiều nặng ký, Công việc vừa ý",
  "Thành công liên miên, Hạnh phúc liền liền",
  "Tân niên sung túc, Gia đình hạnh phúc",
  "Mở hàng năm mới, Tiền tài luôn tới",
  "Kinh doanh thuận lợi, Gia đình hạnh phúc",
  "Năm mới phát đạt, Tiền tài dào dạt",
  "Bán buôn triệu khách, Đơn về triệu đô",
  "Tân xuân thuận lợi, Đón ngàn điều mới",
  "Túi tiền rủng rỉnh, Nhan sắc rung rinh",
  "Việc chạy ro ro, Muốn gì được đó",
  "Tân niên sung túc, Lắm phúc nhiều duyên",
  "Khách đến triền miên, Trong túi nhiều tiền",
  "Giữ mãi an khang, Thắt chặt phúc quý",
  "Tiền vào như nước, Phúc vào như sông",
  "Năm mới rộn ràng, Giàu sang phú quý",
  "Công danh sáng ngời, May mắn khắp nơi",
  "Tài vận lên hương, Kinh doanh tấn tới",
  "Năm Mèo phú quý, Khách quý khách thương",
  "Năm Quý Mão tới, Tiền vào phấp phới",
  "Năm hết Tết đến, Đón hên về nhà",
  "Đơn về trong tết, Đếm tiền không hết",
  "Vàng bạc đầy tủ, Gia chủ phát tài",
  "Trong năm không nhọc, Rước lộc về nhà",
  "Tiền xài thả ga, Không màng giá cả",
  "Bán 1 lời 10, Lợi nhuận tươi cười",
  "Dũng mãnh thương trường, Trăm trận trăm thắng",
  "Buôn may bán đắt, Gặt hái bội thu",
  "Khách đi khách nhớ, Khách đến là mua",
  "Xuân này như ý, Lộc về tiền tỷ",
  "Kinh doanh phơi phới, Mua liền nhà mới",
  "Sự nghiệp dạt dào, Khách đến ào ào",
  "Vạn sự cát tường, Đơn xếp đầy phường",
  "Nay năm con mèo, Tiền ra lèo tèo",
  "Buôn bán hoan hỷ, Năm sau đi Mỹ",
  "Khách đến tiền về, Xuân mới an khang",
  "Năm trước triệu phú, Năm nay tỷ phú",
  "Tấn tài tấn lộc tấn bình an",
  "Vượt bậc doanh thu, Ôm tiền nằm ngủ",
  "Tiền về không ngưng, Số tăng không ngừng",
  "Thuận lợi buôn bán, Vàng mua xả láng",
  "Năm mới hồng phát, Tiền nhiều bát ngát",
  "Gia đình bình an, Kinh doanh phát đạt",
  "Tiền về vô số, Mua vàng đầy rổ",
  "Năm mới bán buôn, trăm đơn một ngày",
  "Năm mới như ý, Doanh thu tiền tỷ",
  "Tết con Mèo nhất quyết thoát nghèo",
  "Năm mới lộc phơi phới, Khách hàng ào ạt tới",
  "Mừng 2023 phát lộc, Tiền vô xồng xộc",
  "Dẻo dai như mèo, Tiền nhiều như kẹo",
  "Năm 2023 phát tài, Không phát phì",
  "Tăng khách tăng đơn, Không tăng cân",
  "Năm 2023 như ý, Vàng mua tính ký",
  "Công danh tấn tới, Tình duyên phơi phới",
  "Sức khỏe bền bỉ, Công danh hết ý",
  "Năm mới năm me, Gia đình mạnh khỏe",
  "Kinh doanh mạnh mẽ, Mọi việc suôn sẻ",
  "Tống tiễn năm cũ, Tiền về như nước lũ",
  "Năm mới sung sức, X10 lợi tức",
  "Năm mới không bực tức, Tiền vào nhà thơm phức",
  "Vui Tết con Mèo, Tiền theo lẽo đẽo",
  "Sự nghiệp thăng tiến,  Tiền tài vô biên",
  "Tiền chất đầy kho, Không lo vất vả",
  "Năm mới sang chảnh, Quán ngày càng bảnh",
  "Phúc lộc tràn đầy, Con cháu sum vầy",
  "Chủ hàng không dí, Khách đuổi không đi",
  "Khách chốt đơn ngay, Không lo bùm hàng",
  "Năm con Mèo, Cười vui ngặt nghẽo",
];
