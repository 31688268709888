declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

interface IMusicURL {
  url: string;
  name: string;
}

class AudioManager {
  protected _context: any;
  protected _source: any;
  protected _gainNode: any;
  protected _audio: any;
  protected _isMute: boolean;

  constructor() {
    this._context = {};
    this._source = {};
    this._gainNode = {};
    this._audio = {};
    this._isMute = false;
  }

  public initAudio = (urls: Array<IMusicURL>, callback: () => void) => {
    this.loadBuffers(
      urls,
      (_: any, audio: any, name: string) => {
        this._audio[name] = audio;
      },
      callback
    );
  };

  public start = (name: string, loop: boolean, volume: number) => {
    if (this._source[name]) {
      this.stop(name);
    }

    this._source[name] = this._context[name].createBufferSource();
    this._gainNode[name] = this._context[name].createGain();
    this._source[name].buffer = this._audio[name];
    this._source[name].loop = loop || false;
    this._gainNode[name].gain.value = volume;

    this._source[name].connect(this._gainNode[name]);
    this._gainNode[name].connect(this._context[name].destination);
    this._source[name].start(this._context[name].currentTime);
  };

  public resume = (name: string) => {
    this._context[name].resume();
  };

  public pause = (name: string) => {
    this._context[name].suspend();
  };

  public stop = (name: string) => {
    this._source[name].stop();
    this._source[name].disconnect(this._gainNode[name]);
    this._gainNode[name].disconnect(this._context[name].destination);
  };

  private loadBuffer = (context: any) => {
    return function loadBufferWorker(
      path: IMusicURL,
      callback: (arg1: any, arg2?: any, arg3?: any) => void
    ) {
      var request = new XMLHttpRequest();
      request.open("GET", path.url, true);
      request.responseType = "arraybuffer";
      request.onload = function () {
        context.decodeAudioData(
          request.response,
          function (theBuffer: any) {
            callback(null, theBuffer, path.name);
          },
          function (err: any) {
            callback(err);
          }
        );
      };
      request.send();
    };
  };

  private loadBuffers = async (
    paths: Array<IMusicURL>,
    callback: (arg1: any, arg2?: any, arg3?: any) => void,
    callbackLoading: () => void
  ) => {
    try {
      const res = await Promise.all(
        paths.map(async (item: IMusicURL) => {
          var AudioContext = window.AudioContext || window.webkitAudioContext;
          this._context[item.name] = new AudioContext();
          this.loadBuffer(this._context[item.name])(item, callback);
        })
      );
      if (res) {
        callbackLoading();
      }
    } catch (err) {}
  };
}

export { AudioManager, type IMusicURL };
