export function getDeviceName() {
  const userAgent = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    return "mobile";
  }
  return "desktop";
}

export function getOperatingSystem() {
  if (typeof navigator !== "undefined") {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) return "Android";

    if (/iPad|iPhone|iPod/.test(userAgent)) return "iOS";

    if (userAgent.indexOf("Win") !== -1) return "Windows";

    if (userAgent.indexOf("Mac") !== -1) return "MacOS";

    if (userAgent.indexOf("Linux") !== -1) return "Linux";
  }

  return "unknown";
}
